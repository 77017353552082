.service_apart_page .item_title{
    font-weight: 600;
    text-align: justify;
    color: #364465;
}

.title_service_apart span{
    font-size: 2rem;
    font-weight: 600;
    border-bottom: 2px solid #01908f;
    color: #1e293b;
}

.block_service_item{
    padding: 0 5%;
}

.service_apart_page{
    padding-top: 20px;
}

.item_service_area{
    display: flex;
    justify-content: center;
}

.item_image{
    overflow: hidden;
    position: relative;
}

.item_image img{
    width: 100%;
    max-height: 200px;
    object-fit:cover;
    overflow: hidden;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.item_service{
    margin-bottom: 30px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 4px;
    outline: 1px solid #01908f;
    outline-offset: 3px;
}

.item_description{
    padding: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.item_description_top{
    height: 55px;
}

.item_description_top span{
    font-size: 0.9rem;
}

.item_description_top h4{
    font-size: 1.2rem;
}

.item_description a{
    color: #202020;
    text-decoration: none;
}
.item_description div:nth-child(1){
    justify-content: space-between;
    align-items: center;
}
.item_service .fas{
    color:#364465
}
.item-icon{
    color:black;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.btn_detail_service_apart{
    background-color: #00908E;
    color:white;
    border-radius: 4px;
    width: 100%;
    border: none;
    padding: 6px 12px;
}

.item_detail{
    display: flex;
    justify-content: space-around;
    margin: 12px auto;
}

.detail_service_apart{
    text-transform: capitalize;
    font-weight: 500;
    background-color: #D9D9D9;
    height: auto;
    width: 45px;
    border-radius: 4px;
    font-size: 0.9rem;
}

.item_price{
    color: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    padding: 0 10px;
    bottom: 5px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.25rem;
    text-shadow: 2px 2px 4px #202020;
}

.item_date{
    background-color: red;
    color: #ffffff;
    position: absolute;
    top: 20px;
    left: -50px;
    transform: rotate(-45deg);
    text-align: center;
    padding: 0 45px;
}

.item_date span{
    font-size: 0.65rem;
}

@media only screen and (max-width: 991px) {
    .detail_service_apart{
        font-size: 0.8rem;
    }

    .block_service_item{
        padding: 0 8%;
    }
}

@media only screen and (max-width: 767px) {
    .item_description_top{
        height: 50px;
    }

    .item_price{
        font-size: 1rem;
    }

    .item_description_top h4{
        font-size: 1.1rem;
    }

    .block_service_item{
        padding: 0 6%;
    }

    .title_service_apart span{
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 610px) {
    .item_description_top{
        height: 45px;
    }

    .detail_service_apart{
        width: 40px;
        font-size: 0.7rem;
    }

    .block_service_item{
        padding: 0 0%;
    }

    .btn_detail_service_apart{
        font-size: 0.8rem;
        width: 95%;
    }
}

@media only screen and (max-width: 430px) {

    .item_description_top{
        height: 50px;
    }

    .item_description_top h4{
        font-size: 1.1rem;
    }

    .item_description_top span{
        font-size: 0.8rem;
    }

    .detail_service_apart{
        font-size: 0.8rem;
    }
}