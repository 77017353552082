.contact_body_area {
    padding: 0 70px;
    display: flex;
    justify-content: space-around;
}

.block_image {
    width: 60%;
    height: 100%;
    position: relative;
}

.contact_name{
    position: absolute;
    bottom: 0;
    background-color: #00908E;
    border-bottom-left-radius: 10px;
    color: #fff;
    padding: 10px 15px;
    opacity: 0.85;
    font-weight: 600;
}

.block_contact {
    display: flex;
    background: #f3f2f3;
    border-radius: 10px;
    margin: 0 10px;
}

.image_area img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.contact_infor_area {
    width: 40%;
    display: flex;
    align-items: center;
    padding: 0 12px;
}

.image_icon {
    width: 30px;
    height: 30px;
}

.contact_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    color: #00908E;
}

@media only screen and (max-width: 1030px) {

    .contact_details span {
        font-size: 0.85rem;
    }

    .contact_name{
        font-size: 0.9rem;
        padding: 9px 14px;
    }

}

@media only screen and (max-width: 991px) {

    .contact_body_area {
        padding: 0 40px;
    }

}

@media only screen and (max-width: 830px) {

    .contact_details span {
        font-size: 0.75rem;
    }

    .image_icon {
        width: 27px;
        height: 27px;
    }

    .contact_name{
        font-size: 0.8rem;
        padding: 8px 13px;
    }

    .contact_body_area{
        padding: 0 20px;
    }

}


@media only screen and (max-width: 720px) {

    .contact_details span {
        font-size: 0.65rem;
    }

    .contact_details{
        padding: 10px 0;
    }

    .image_icon {
        width: 25px;
        height: 25px;
    }

    .contact_name{
        font-size: 0.7rem;
        padding: 7px 12px;
    }

    .contact_body_area{
        padding: 0 15px;
    }

}

@media only screen and (max-width: 630px) {

    .contact_details span {
        font-size: 0.5rem;
    }

    .image_icon {
        width: 23px;
        height: 23px;
    }

    .contact_name{
        font-size: 0.6rem;
        padding: 6px 12px;
    }
}

@media only screen and (max-width: 575px) {

    .contact_body_area {
        display: block;
    }
    
    .block_contact{
        width: 75%;
        margin: 10px auto;
    }

    .contact_details span {
        font-size: 0.65rem;
    }

    .image_icon {
        width: 25px;
        height: 25px;
    }
}

@media only screen and (max-width: 460px) {

    .block_contact{
        width: 85%;
    }

    .contact_details span {
        font-size: 0.6rem;
    }

    .image_icon {
        width: 24px;
        height: 24px;
    }
}

@media only screen and (max-width: 400px) {

    .block_contact{
        width: 95%;
    }
}