.sellfHouseOwner{
    padding: 0;
    margin-top: 2.2rem;
    box-sizing: border-box;
    scroll-behavior: smooth;  
}
.greenLine{
    width: 1rem;
    height: 70%;
    background: #00908E;
}
.sellhouse_info{
    text-align: start;
    margin-left: 0.9rem;
}
.sellhouse_info h1{
    font-weight: 700;
    font-size: 2rem;
    line-height: 3.5rem;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.14);
    margin-bottom: 1.5rem;
}
.sellhouse_info p{
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #000000;
}

.button-moredetail button{
    height: 3rem;
    background-color: white;
    border: #00908E 2px solid;
    color:#00908E;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.4rem;
}
.button-moredetail .icon_moredetail{
    display: inline-flex;
    font-size: medium;
}
.rentoutHouse{
    margin-top: 2.5rem;
}
.title_manage{
    margin-top: 2rem;
    font-weight: 700;
    font-size: 30px;
    line-height: 54px;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.14);
}

.block_image_none{
    display: none;
}

@media only screen and (max-width: 1030px) {

    .sellhouse_info h1{
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .sellhouse_info p{
        font-size: 0.7rem;
    }

    .button-moredetail button{
        font-size: 0.9rem;
        margin-top: 1rem;
    }

    .title_manage{
        margin-top: 1rem;
    }

}

@media only screen and (max-width: 767px) {

    .sellfHouseOwner{
        margin-top: 1.5rem;
    }

    .rentoutHouse{
        margin-top: 1.5rem;
    }

    .block_image_none{
        display: block;
        margin-top: 1.5rem;
    }

    .block_image_hidden_sm{
        display: none;
    }

    .block_image_sm{
        margin-top: 1.5rem;
    }

    .button-moredetail button{
        height: 2.5rem;
        font-size: 0.9rem;
        margin-top: 0.5rem;
    }

}

@media only screen and (max-width: 575px) {

    .sellfHouseOwner{
        margin-top: 1rem;
    }

    .rentoutHouse{
        margin-top: 1rem;
    }

    .block_image_none, .block_image_sm{
        margin-top: 0.8rem;
    }

    .sellhouse_info h1{
        font-size: 1.6rem;
    }

    .sellhouse_info p{
        font-size: 0.7rem;
    }

    .button-moredetail button{
        height: 2.3rem;
        font-size: 0.8rem;
        margin-top: 0.2rem;
    }

    .image_manage img{
        margin-top: 1rem;
        width: 250px;
        height: 375px;
    }

}

@media only screen and (max-width: 440px) {

    .sellhouse_info h1{
        font-size: 1.3rem;
        margin-bottom: 0rem;
    }

    .sellhouse_info p{
        font-size: 0.55rem;
    }

}