.slider_area {
    position: relative;
}

/*----------------------------------*/
.details_apartment_area {
    background-color: #f1f1f1;
}

.in_slider_display_responsive {
    display: none;
}

.in_slider_area {
    display: flex;
    position: absolute;
    bottom: 0px;
    z-index: 1000;
    width: 100%;
    padding: 0 100px 0 125px;
}

.in_slider_area .in_slider_display {
    background-color: #fff;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 10px 30px;
}

.in_slider_display img {
    margin-right: 20px;
}

.in_slider_display span {
    font-weight: 700;
}

.in_slider_display_photo {
    display: flex;
    justify-content: space-between;
}

.button_pagination_area {
    width: 40%;
    display: flex;
    justify-content: space-between;
}

.button_pagination_area button {
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
}

.not_display {
    visibility: hidden;
}

/*----------------------------------*/

.apart_detail_area {
    padding: 0 125px;
    background-color: #f1f1f1;
}

.apart_detail_area_left,
.apart_detail_area_middle,
.apart_detail_area_right {
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.apart_detail_area_left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.apart_detail_area_left_block {
    border-right: 2px solid #9c9d9d;
    width: 100%;
}

.apart_detail_area_left_block span {
    font-weight: 800;
    color: #9c9d9d;
}

.apart_detail_area_left_block p {
    font-weight: 700;
    font-size: 1.5rem;
}

.apart_name span {
    font-weight: 700;
    font-size: 1.3rem;
}

.apart_detail_area_middle div {
    margin-bottom: 12px;
}

.apart_detail_icon_infor .title_icon {
    color: #9d9c9d;
    font-weight: 600;
}

.apart_detail_icon_infor .title_icon .number {
    color: black;
    font-weight: 700;
}

.apart_detail_icon_infor {
    display: flex;
    justify-content: space-between;
}

.icon_details {
    height: 20px;
    width: 20px;
}

.apart_detail_area_right_1,
.apart_detail_area_right_2 {
    display: flex;
}

.apart_detail_area_right_1 div,
.apart_detail_area_right_2 div {
    width: 50%;
}

.apart_detail_area_right_2 {
    border-left: 2px solid #9c9d9d;
    padding: 6.5px 0;
}

.apart_detail_area_right_1 div span,
.apart_detail_area_right_2 div span,
.apart_detail_area_right_3 span {
    font-weight: 800;
    color: #9c9d9d;
    font-size: 0.9rem;
}

.apart_detail_area_right_1 div p,
.apart_detail_area_right_2 div p,
.apart_detail_area_right_3 p {
    font-size: 0.95rem;
}

/*----------------------------------*/

.features_area {
    margin-top: 50px;
    padding: 0 125px;
}

.feature_title,
.features_item {
    background-color: #fff;
}

.features_item div {
    padding: 12px 0 12px 100px;
    display: flex;
    justify-content: flex-start;
}

.feature_title {
    padding: 30px 0;
}

.feature_title span {
    font-size: 1.8rem;
    font-weight: 700;
}

/*----------------------------------*/

@media only screen and (max-width: 1030px) {
    .features_item div{
        padding: 12px 0 12px 70px;
    }
}

@media only screen and (max-width: 991px) {
    .in_slider_area {
        display: none;
    }

    .in_slider_display_responsive {
        display: block;
        background-color: #f1f1f1;
        padding: 0 125px;
    }

    .in_slider_display_responsive .in_slider_display {
        padding: 10px 150px !important;
        background-color: #fff;
        border-bottom: 1px #f1f1f1 solid;
    }

    .apart_detail_area_right_2,
    .apart_detail_area_left_block {
        border: none;
    }

    .apart_detail_area_left,
    .apart_detail_area_middle,
    .apart_detail_area_right {
        padding: 30px 80px;
        border-top: 1px #f1f1f1 solid;
    }

    .apart_detail_area_right {
        display: flex;
        justify-content: space-between;

    }

    .apart_detail_area_right_1,
    .apart_detail_area_right_2 {
        width: 33.33%;
        display: block;
    }

    .apart_detail_area_right_1 div,
    .apart_detail_area_right_2 div {
        width: 100%;
        text-align: center;
        border-right: 2px #f1f1f1 solid;
    }

    .apart_detail_area_right_3 {
        width: 33.33%;
    }

    .apart_detail_area_right_3 div {
        text-align: center;
    }

    .in_slider_display_responsive,
    .apart_detail_area,
    .features_area {
        padding: 0 60px;
    }

    .features_item div{
        padding: 12px 0 12px 40px;
    }
}

@media only screen and (max-width: 830px) {
    .apart_description {
        font-size: 0.8rem;
    }

    .apart_detail_area_left,
    .apart_detail_area_middle,
    .apart_detail_area_right {
        padding: 12px 80px;
    }

    .apart_detail_area_left_block span {
        font-size: 0.8rem;
    }

    .apart_detail_area_left_block p {
        font-size: 1.3rem;
    }

    .apart_name span {
        font-size: 1.1rem;
    }

    .icon_details {
        width: 17px;
        height: 17px;
    }

    .title_icon {
        font-size: 0.8rem;
    }

    .features_item div{
        padding: 12px 0 12px 30px;
    }
}

@media only screen and (max-width: 776px) {


    .feature_item_name{
        font-size: 0.8rem;
    }

}

@media only screen and (max-width: 724px) {
    .in_slider_display_responsive .in_slider_display {
        padding: 10px 120px !important;
    }

    .apart_detail_area,
    .in_slider_display_responsive,
    .features_area {
        padding: 0 100px;
    }

    .apart_detail_area_left,
    .apart_detail_area_middle,
    .apart_detail_area_right {
        padding: 12px 50px;
    }

    .feature_title span{
        font-size: 1.5rem;
    }
    
    .features_item div{
        padding: 12px 0 12px 20px;
    }
}

@media only screen and (max-width: 656px) {
    .in_slider_display_responsive .in_slider_display {
        padding: 10px 80px !important;
    }

    .apart_detail_area,
    .in_slider_display_responsive,
    .features_area {
        padding: 0 80px;
    }

    .feature_item_name{
        font-size: 0.75rem;
    }
}

@media only screen and (max-width: 600px) {

    .in_slider_display_responsive .in_slider_display {
        padding: 10px 50px !important;
    }

    .button_pagination_area {
        width: 50%;
    }

    .apart_description {
        font-size: 0.65rem;
    }

    .apart_detail_area_left,
    .apart_detail_area_middle,
    .apart_detail_area_right {
        padding: 12px 30px;
    }

    .apart_detail_area_left_block span {
        font-size: 0.75rem;
    }

    .apart_detail_area_left_block p {
        font-size: 1.2rem;
    }

    .apart_name span {
        font-size: 1rem;
    }

    .icon_details {
        width: 15px;
        height: 15px;
    }

    .title_icon {
        font-size: 0.75rem;
    }

    .apart_detail_area_right_1 div span,
    .apart_detail_area_right_2 div span,
    .apart_detail_area_right_3 div span {
        font-size: 0.75rem;
    }

    .apart_detail_area_right_1 div p,
    .apart_detail_area_right_2 div p,
    .apart_detail_area_right_3 div p {
        font-size: 0.75rem;
    }

    .feature_item_name{
        font-size: 0.65rem;
    }

    .feature_title{
        padding: 20px 0;
    }

    .feature_title span{
        font-size: 1.3rem;
    }

}

@media only screen and (max-width: 495px) {

    .in_slider_display_responsive .in_slider_display {
        padding: 10px 20px !important;
    }

    .button_pagination_area {
        display: flex;
        align-items: center;
    }

    .in_slider_display span {
        font-size: 0.8rem;
    }

    .button_pagination_area {
        width: 50%;
    }

    .apart_detail_area_left,
    .apart_detail_area_middle,
    .apart_detail_area_right {
        padding: 12px 5px;
    }

    .in_slider_display_responsive,
    .apart_detail_area,
    .features_area {
        padding: 0 20px;
    }

    .feature_title{
        padding: 15px 0;
    }

    .features_item div{
        padding: 12px 0 12px 10px;
    }
}