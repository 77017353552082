.bg-green {
    background-color:  #00908E !important;
}

.title_news{
    text-decoration: none;
    color: #202020;
}

.title_news_area{
    height: 70px;
}

.bg-gray {
    background-color: #F0F0F0 !important;
}
.prevNext_news{
    justify-content: space-between;
    align-items: center;
    display: list-item;
}
.left_news {
    border-radius: 5px;
    padding: 10px;
    background-color:#00908E;
    color: white;
    width: 60px;
    height: 35px;
    margin-right: 200px;
    cursor: pointer;

}
.right_news {
    border-radius: 5px;
    padding: 10px;
    background-color:#00908E;
    color: white;
    width: 60px;
    height: 35px;
    cursor: pointer;
    
}
.lastest {
    font-size: 2rem;
    top: 100px;
}

.lastest span{
    border-bottom: 2px solid #01908f;
    color: #1e293b;
}

.all_buttons_news{
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px;
}
.buttons_news{
    padding: 12px ;
}

.cart_body_news{
    padding: 20px 30px 50px 30px;
}

.card_title_news, .card_text_news{
    text-align: justify;
}

.news_information{
    display: flex;
    justify-content: space-between;
}

.img_card_news{
    height: 200px;
}

@media only screen and (max-width: 1120px){

    .card_text_news{
        font-size: 0.8rem;
    }

    .news_information div{
        font-size: 0.8rem;
    }

    .card_title_news{
        font-size: 1rem;
    }

    .cart_body_news{
        padding: 20px 30px 20px 30px;
    }

    .title_news_area{
        height: 50px;
    }
}

@media only screen and (max-width: 767px){

    .lastest{
        font-size: 1.8rem;
    }

    .box-news{
        width: 70%;
    }

    .buttons_news{
        display: flex;
        justify-content: center;
    }

    .title_news_area{
        height: 45px;
    }

}

@media only screen and (max-width: 530px){

    .box-news{
        width: 90%;
    }

    .card_title_news{
        font-size: 0.85rem;
    }
    .lastest{
        font-size: 1.65rem;
    }
}

@media only screen and (max-width: 395px){

    .box-news{
        width: 100%;
    }

    .lastest{
        font-size: 1.5rem;
    }

    .card_title_news{
        font-size: 0.8rem;
    }

    .title_news_area{
        height: 40px;
    }
}