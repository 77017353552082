.aboutUs_main {
    scroll-behavior: smooth;
    margin-top: 80px;
}

.firts_about_main {
    margin-top: 30px;
}

.second_about_main {
    margin-top: 35px;
}

.aboutText {
    padding: 70px;
}

.aboutText .title_aboutus {
    width: 200px;
    rotate: 270deg;
    margin-left: -80px;
    margin-top: 70px;
    color: #00908E;
    font-weight: 800px;
}

.aboutText .title_aboutus_2 {
    width: 200px;
    rotate: 90deg;
    margin-left: -60px;
    margin-top: 70px;
    color: #00908E;
    font-weight: 800px;
}

.aboutTextP p {
    text-align: justify;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 22px;
    color: black;
    font-style: normal;

}

.aboutImage img {
    width: 100%;

}

.small_screen_image_2 {
    display: none;
}

@media only screen and (max-width: 1030px) {
    .aboutTextP p {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 930px) {
    .second_about_main {
        margin-top: 0px;
    }

    .aboutUs_main {
        margin-top: 50px;
    }
}


@media only screen and (max-width: 767px) {

    .title_aboutus,
    .title_aboutus_2 {
        font-size: 2rem;
    }

    .aboutTextP p {
        font-size: 0.75rem;
    }

    .aboutText .title_aboutus_2 {
        margin-left: 0px;
        margin-left: -30px;
    }

    .large_image_2 {
        display: none;
    }

    .small_screen_image_2 {
        display: block;
    }

}

@media only screen and (max-width: 674px) {

    .title_aboutus,
    .title_aboutus_2 {
        font-size: 1.7rem;
    }

    .aboutTextP p {
        font-size: 0.7rem;
    }

    .aboutText .title_aboutus_2 {
        margin-left: -20px;
    }

}

@media only screen and (max-width: 575px) {
    .aboutTextP p {
        font-size: 0.6rem;
    }
}

@media only screen and (max-width: 490px) {

    .aboutText .title_aboutus_2 {
        margin-left: -50px;
    }

    .aboutText .title_aboutus {
        margin-left: -70px;
    }
}

@media only screen and (max-width: 430px) {

    .aboutText .title_aboutus_2 {
        margin-left: -65px;
    }

    .aboutText .title_aboutus {
        margin-left: -75px;
    }

    .title_aboutus,
    .title_aboutus_2 {
        font-size: 1.5rem;
    }

    .aboutTextP p {
        font-size: 0.55rem;
    }

}