/*UPPER AREA*/

.upper_nav_area .upper_nav_list{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid #00908E;
}

.upper_nav_area .upper_nav_list a{
    text-decoration: none;
    color: #1e293b;
    font-size: 1rem;
    font-weight: 630;
}

.upper_nav_area .upper_nav_list a:hover{
    color: #00908E;
}

/*---------*/

@media only screen and (max-width: 800px) {

    .upper_nav_area .upper_nav_list{
        height: 40px;
    }

    .upper_nav_area .upper_nav_list a{
        font-size: 0.95rem;
    }

}

@media only screen and (max-width: 660px) {

    .upper_nav_area .upper_nav_list{
        height: 35px;
    }

    .upper_nav_area .upper_nav_list a{
        font-size: 0.85rem;
    }
}

@media only screen and (max-width: 570px) {

    .upper_nav_area .upper_nav_list a{
        font-size: 0.8rem;
    }

}

@media only screen and (max-width: 430px) {

    .upper_nav_area .upper_nav_list{
        height: 30px;
    }

    .upper_nav_area .upper_nav_list a{
        font-size: 0.7rem;
    }

}