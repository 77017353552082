.banner{
    height: 300px;
    background: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 50px;
    position: relative;
}

.description_cover_center{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.section_cover_area{
    width: 100%;
    height: 100%;
    background: rgb(29 34 43 / 50%);
}

.title_cover{
    width: 100%;
    margin-top: 100px;
}

.title_cover h2{
    color: #fff;
}

.description_cover_area{
    background-color: #fff;
    width: 80%;
    padding: 35px 25px 15px 25px;
}

.description_cover_area_details_left{
    width: 15%;
    border-right: 1px solid black;
    text-align: center;
    font-size: 0.85rem;
}

.description_cover_area_details_middle{
    width: 65%;
    text-align: justify;
}

.description_cover_area_details_middle span{
    font-size: 0.8rem;
}

.description_cover_area_details_right{
    width: 20%;
}

.btn_view_all{
    background-color: #00908E;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    width: 100%;
    font-size: 14px;
}

.btn_view_all a{
    text-decoration: none;
    color: #fff;
}

@media only screen and (max-width: 900px) {

    .description_cover_area_details_left span{
        font-size: 0.9rem;
    }

    .banner{
        height: 380px;
    }
}

@media only screen and (max-width: 830px) {

    .description_cover_area_details_left span{
        font-size: 0.8rem;
    }

    .description_cover_area_details_middle span{
        font-size: 0.75rem;
    }

    .btn_view_all{
        padding: 10px 18px;
        border-radius: 5px;
        font-size: 0.7rem;
    }

    .banner{
        height: 360px;
    }
}

@media only screen and (max-width: 710px) {

    .description_cover_area{
        padding-top: 30px;
    }

    .description_cover_area_details_left span{
        font-size: 0.7rem;
    }

    .description_cover_area_details_middle span{
        font-size: 0.7rem;
    }

    .btn_view_all{
        padding: 10px 12px;
        border-radius: 5px;
        font-size: 0.7rem;
    }

    .banner{
        height: 340px;
    }
}

@media only screen and (max-width: 600px) {

    .description_cover_area{
        padding-top: 25px;
    }

    .description_cover_area_details_left span{
        font-size: 0.6rem;
    }

    .description_cover_area_details_middle span{
        font-size: 0.6rem;
    }

    .btn_view_all{
        padding: 8px 5px;
        border-radius: 5px;
        font-size: 0.6rem;
    }

    .banner{
        height: 320px;
    }
}

@media only screen and (max-width: 520px) {

    .description_cover_area_details_left span{
        font-size: 0.85rem;
    }

    .btn_view_all{
        font-size: 0.8rem;
        width: 75%;
    }

    .description_cover_area_details_left{
        width: 100%;
        border: none;
        margin-bottom: 10px;
    }

    .description_cover_area_details_right{
        width: 100%;
    }

    .description_cover_area_details_middle{
        display: none;
    }

    .banner{
        height: 300px;
    }
}