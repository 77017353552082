@import url('https://fonts.googleapis.com/css2?family=Hurricane&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300;1,600;1,700;1,800&display=swap');

.ChooseUs{
    background: rgba(217, 217, 217, 0.12);
    font-family: 'Open Sans';
    margin-top: 35px;
    padding: 20px;
}

.ChooseUs .title_chooseUs{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 48px;
    text-transform: capitalize;
    color: #000000;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.14);
}
.ChooseUs .list_chooseUs{
    margin-top: 30px;
    text-align: start;
    font-size: 1rem;
    line-height: 25px;
    letter-spacing: 0.05em;
    font-family: 'Open Sans';
}
.ChooseUs .WhyChoose_img1{
    margin-top: 30px;
    object-fit: contain;
    object-position: top;
}

@media only screen and (max-width: 1030px) {

    .ChooseUs .title_chooseUs{
        font-size: 1.6rem;
    }

    .list_chooseUs li{
        font-size: 0.85rem;
    }
}

@media only screen and (max-width: 767px) {

    .ChooseUs .title_chooseUs{
        text-align: center;
        font-size: 1.4rem;
    }

    .list_chooseUs li{
        font-size: 0.65rem;
    }

    .list_chooseUs li{
        font-size: 0.75rem;
    }

    .ChooseUs .list_chooseUs{
        margin-top: 20px;
    }
    .ChooseUs .WhyChoose_img1{
        margin-top: 20px;
    }
}

@media only screen and (max-width: 630px) {

    .ChooseUs .title_chooseUs{
        font-size: 1.2rem;
    }

    .ChooseUs .list_chooseUs{
        margin-top: 10px;
    }
    .ChooseUs .WhyChoose_img1{
        margin-top: 10px;
    }
}

@media only screen and (max-width: 575px) {

    .ChooseUs .WhyChoose_img1{
        margin-top: 10px;
    }

    .list_chooseUs{
        display: flex;
        justify-content: center;
    }

    .list_chooseUs li{
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 500px) {

    .ChooseUs .list_chooseUs{
        margin-top: 0px;
    }

    .ChooseUs .title_chooseUs{
        font-size: 1rem;
    }

    .list_chooseUs li{
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 400px) {

    .list_chooseUs li{
        font-size: 0.6rem;
    }
}