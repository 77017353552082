.calltoaction{
    position: fixed;
    right: 0;
    bottom: calc(50% - 250px);
    z-index: 9999;
}

.calltoaction ul {
    list-style: none;
    margin: 0;
}

.calltoaction ul li a {
    padding: 2px;
    display: inline-block;
    box-shadow: 0 5px 10px rgb(0 0 0 / 15%), 0 4px 15px rgb(0 0 0 / 13%);
    border-radius: 50%;
    margin: 5px;
}

.calltoaction img {
    width: 55px;
}

@media only screen and (max-width: 700px) {

    .calltoaction img {
        width: 50px;
    }
}

@media only screen and (max-width: 500px) {

    .calltoaction img {
        width: 40px;
    }
}


