.list_service_house_onwer_area{
    padding: 20px 11% 0 11%;
}

.tittle_list_service_house_onwer{
    text-align: left;
    margin-bottom: 12px;
    border-bottom: solid #01908f 2px;
    width: fit-content;
}

.tittle_list_service_house_onwer span{
    font-size: 2.2vw;
    font-weight: 600;
    color: #1e293b;
}

.service_house_onwer_item{
    padding: 0 8px !important;
    position: relative;
}

.service_house_onwer_item img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.service_house_onwer_item_name{
    position: absolute;
    bottom: 0;
    left: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: calc(100% - 16px);
    background: rgb(29 34 43 / 29%);
    border-radius: 5px;
}

.service_house_onwer_item_name .service_house_onwer_title_name span{
    color: #fff;
    font-size: 1.7vw;
}

.circle_house_onwer_name{
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2.5px solid #fff;
    border-radius: 50%;
}

.circle_house_onwer_name span{
    font-weight: 600;
    color: #fff;
    font-size: 2.2vw;
}

.service_house_onwer_title_name{
    height: 75px;
}

@media only screen and (max-width: 850px) {

    .service_house_onwer_title_name{
        height: 50px;
    }

    .circle_house_onwer_name{
        width: 35px;
        height: 35px;
    }

    .tittle_list_service_house_onwer span{
        font-size: 2.5vw;
    }
}

@media only screen and (max-width: 800px) {

    .tittle_list_service_house_onwer span{
        font-size: 2.5vw;
    }
}

@media only screen and (max-width: 767px) {

    .circle_house_onwer_name span{
        font-size: 3vw;
    }

    .service_house_onwer_item_name .service_house_onwer_title_name span{
        font-size: 3vw;
    }
    
}

@media only screen and (max-width: 650px) {

    .service_house_onwer_title_name{
        height: 40px;
    }

    .circle_house_onwer_name{
        width: 30px;
        height: 30px;
    }

    .tittle_list_service_house_onwer span{
        font-size: 4vw;
    }

    .tittle_list_service_house_onwer span{
        font-size: 3vw;
    }
}

@media only screen and (max-width: 575px) {

    .tittle_list_service_house_onwer span{
        font-size: 4.2vw;
    }
}