.news_details_area .news_details_title{
    font-size: 2.5rem;
}

.news_details_sub_infor span{
    padding: 12px;
}

.news_details_area{
    width: 75%;
}

.news_details_description img{
    width: 100%;
}

.news_details_description h1{
    font-size: 1.8rem;
}

.news_details_description h2{
    font-size: 1.5rem;
}

.news_details_description h3{
    font-size: 1.2rem;
}

.news_details_description h4{
    font-size: 1rem;
}

/*------------------------*/

.section_news_list{
    padding: 20px 10% 0 10%;
}

.news{
    width: 95%;
    margin: auto auto 30px;
    border-radius: 4px;
}

.news_image{
    position: relative;
    height: 100%;
    background: rgb(29 34 43 / 30%);
}

.news_title{
    position: absolute;
    bottom: 0;
    left: 10px;
    text-align: left;
}

.title_news h4{
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
}

.news_image_cover{
    height: 12rem;
    background: no-repeat;
    background-size: cover;
    background-position: center;
}

.news-description{
    display: block;
    padding: 15px;
    background-color: #f6f6f6;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    text-align: justify;
    font-size: 0.9rem;
}

.news-start span{
    font-size: 2.2vw;
    font-weight: 500;
    text-align: justify;
    text-transform: uppercase;
    color: #1e293b;
}

.news-start a{
    border-bottom: solid #161616 2px;
}

.border_news_section{
    width: fit-content;
    margin-bottom: 12px;
    border-bottom: solid #01908f 2px;
}

.news-intro{
    height: 50px;
    font-size: 0.8rem;
}

.news{
    width: 100%;
}

@media only screen and (max-width: 1100px){

    .news-infor span{
        font-size: 0.8rem;
    }

    .news_details_area .news_details_title{
        font-size: 2.3rem;
    }

}

@media only screen and (max-width: 991px){

    .news-intro{
        display: none;
    }
    
    .title_news h4{
        font-size: 0.9rem;
    }

    .news_image_cover{
        height: 10rem;
    }

    .news-infor span{
        font-size: 0.7rem;
    }

}

@media only screen and (max-width: 800px){

    .news-start span{
        font-size: 2.5vw;
    }

}

@media only screen and (max-width: 767px){

    .news_details_area .news_details_title{
        font-size: 2.2rem;
    }

    .news_details_area{
        width: 80%;
    }

    .news_title{
        height: auto;
    }

    .news{
        width: 80%;
    }

    .border_news_section{
        margin-bottom: 0px;
    }

    .news_block{
        padding: 20px 10% 0 10%;
    }
}

@media only screen and (max-width: 699px){

    .news-text h4 a{
        font-size: 1.1rem;
    }

    .news_details_area .news_details_title{
        font-size: 2rem;
    }

    .news_details_area{
        width: 85%;
    }

    .news{
        width: 85%;
    }

    .news_details_description p{
        font-size: 0.9rem;
    }

    .news_details_description h1{
        font-size: 1.6rem;
    }
    
    .news_details_description h2{
        font-size: 1.35rem;
    }
    
    .news_details_description h3{
        font-size: 1.15rem;
    }
    
    .news_details_description h4{
        font-size: 1rem;
    }

}

@media only screen and (max-width: 650px){

    .news-start span{
        font-size: 3vw;
    }

}

@media only screen and (max-width: 650px){

    .news-start span{
        font-size: 4.2vw;
    }

}

@media only screen and (max-width: 550px){

    .news_details_area .news_details_title{
        font-size: 1.8rem;
    }

    .news_details_area{
        width: 95%;
    }

    .news{
        width: 100%;
    }
}

@media only screen and (max-width: 410px){
    
    .news_details_area{
        width: 100%;
    }
    .news_details_description p{
        font-size: 0.8rem;
    }

    .news_details_description h1{
        font-size: 1.45rem;
    }
    
    .news_details_description h2{
        font-size: 1.2rem;
    }
    
    .news_details_description h3{
        font-size: 1.05rem;
    }
    
    .news_details_description h4{
        font-size: 0.9rem;
    }
    .news{
        width: 95%;
    }

}