.black{
    /* height: 150px; */
    /* display: flex; */
    justify-content: space-around;
}

.agn, .cpn{
    height: 90px;
    display: flex;
    margin: 0;
}

.agn{
    background-color: #282434;
}

.cpn{
    background-color: #181726;
}

.black-pic{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    color: white;
    width: 15%;
}

.txt-area{
    margin: auto 20px auto 0;
    text-align: justify;
    color: #ffffff;
    width: 65%;
}

.txt-area p{
    margin: auto;
    font-size: 0.7rem;
}

@media only screen and (max-width: 1030px){

    .black-pic{
        font-size: 30px;
        width: 18%;
    }

    .txt-area h5{
        font-size: 0.9rem;
    }

    .txt-area p{
        font-size: 0.65rem;
    }
}


@media only screen and (max-width: 850px){
    .black{
        display: none;
    }
}
