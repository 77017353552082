
/*NAVBAR AREA*/

.nav_bar_area{
    border-bottom: 5px solid #00908E;
}

.nav_bar_list{
    width: 100%;
}

.nav_bar_area .nav_bar_list{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 85px;
}

.nav_middle{
    height: 85px;
    width: 85px;
}

.nav_middle img{
    height: 100%;
    width: 100%;
}

.nav_left, .nav_right{
    width: 46%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.nav_left a, .nav_right a{
    text-decoration: none;
    color: #1e293b;
    font-weight: 550;
}

.nav_left a:hover, .nav_right a:hover{
    color: #00908E;
}

.nav_left a, .nav_middle a, .nav_right a{
    margin-right: 8px;
    padding-right: 5px;
}

.nav_left a, .nav_right a{
    border-right: 1px solid #c6c6c6;
}

/*-----------*/

@media only screen and (max-width: 1030px) {
    .nav_left, .nav_right{
        width: 46%;
    }
}

@media only screen and (max-width: 800px) {

    .nav_bar_area .nav_bar_list, .nav_middle{
        height: 55px;
    }

    .nav_bar_list a{
        font-size: 0.85rem;
    }

    .nav_middle{
        width: 55px;
    }

}

@media only screen and (max-width: 660px) {

    .nav_bar_area .nav_bar_list, .nav_middle{
        height: 50px;
    }

    .nav_middle{
        width: 50px;
    }

    .nav_bar_list a{
        font-size: 0.75rem;
    }
}

@media only screen and (max-width: 570px) {

    .nav_bar_area .nav_bar_list, .nav_middle{
        height: 40px;
    }

    .nav_middle{
        width: 40px;
    }

    .nav_bar_list a{
        font-size: 0.7rem;
    }

    .nav_left, .nav_right{
        width: 45%;
    }
}

@media only screen and (max-width: 430px) {

    .nav_bar_area .nav_bar_list, .nav_middle{
        height: 35px;
    }

    .nav_middle{
        width: 35px;
    }

    .nav_bar_list a{
        font-size: 0.6rem;
    }

    .nav_left, .nav_right{
        width: 43%;
    }
}