.footer {
    background: #FFFFFF;
    color: #393a42;
}

.banner_footer{
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: no-repeat;
    background-size: cover!important;
    background-position: center!important;
    position: relative;
}

.background_cover_footer_contact{
    width: 100%;
    height: 100%;
    background: rgb(29 34 43 / 50%);
    display: flex;
    justify-content: center;
}

.footer_contact_area{
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .fa-instagram,
.footer .fa-facebook,
.footer .fa-twitter {
    /* color: #393a42 !important; */
    font-size: 22px;
}

.footer-title {
    font-size: 21px;
    font-weight: bold;
    color: #104C48;
}

.footer-ul {
    margin: 0;
    padding: 0;
}

.footer-ul li {
    list-style: none;
    margin-left: 0;
    margin-bottom: 10px;
}

.footer-ul li :hover{
    color: #00908E;
}

.footer-ul a{
    color: #393a42;
    text-decoration: none;
}

.footer-other-text {
    font-size: 15px;
    color: #393a42;
}

.footer-title img {
    width: 35px;
    height: 35px;
}

.footer .fa-facebook-f,
.footer .fa-twitter,
.footer .fa-instagram {
    color: #FFFFFF !important;
    font-size: 20px;
}

.inp-footer {
    outline: 0;
    border: 0;
}

.footer-social {
    display: flex;
    margin: 0 0 16px;
}

.footer-social-item {
    width: 45px;
    height: 45px;
    margin: 5px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 50%;
}

.footer-info-item {
    width: 25px;
}

.footer span {
    text-align: justify;
}

.contact-btn {
    margin: 15px auto;
    background-color: transparent;
    padding: 15px 30px;
    border-radius: 4px;
    border: solid #FFFFFF 2px;
    color: #FFFFFF;
}

.contact-btn:hover{
    background-color: #007bff;
}

.fbicon{
    background: #4267B2;
}

.twticon{
    background: #00ACED;
}

.igicon{
    background: #C13584;
}

.button_contact_footer{
    text-decoration: none;
    color: #FFFFFF;
}

.button_contact_footer:hover{
    color: #FFFFFF;
}

@media only screen and (max-width: 700px) {

    .contact-btn {
        margin: 15px auto;
        padding: 12px 25px;
    }

    .contact-btn a{
        font-size: 0.85rem;
    }

    .infor-company{
        font-size: 0.8rem;
    }

    .footer-social-item {
        width: 35px;
        height: 35px;
    }

    .banner_footer{
        height: 250px;
    }
}

@media only screen and (max-width: 550px) {
    .banner_footer{
        height: 220px;
    }

    .contact-btn {
        margin: 15px auto;
        padding: 8px 15px;
    }

    .contact-btn a{
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 440px) {

    .contact-btn {
        margin: 15px auto;
        padding: 8px 16px;
    }

    .infor-company{
        font-size: 0.65rem;
    }

    .footer-social-item {
        width: 30px;
        height: 30px;
    }

    .footer-title{
        font-size: 1rem;
    }

    .banner_footer{
        height: 200px;
    }
}