.block_image_map{
    height: fit-content;
    padding: 0 3rem;
}

.image_map{
    max-height: 66vh;
}

.project_hot, .apartment_by_project{
    padding: 0 3rem;
}

.block_hot_project{
    display: flex;
    justify-content: space-around;
}

.project_hot_title button{
    background-color: #048c90;
    color: #fff;
    padding: 12px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    width: 100%;
}

.apartment_item{
    position: relative;
    cursor: pointer;
}

.apartment_item img{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;   
}

.apartment_item img:hover{
    box-shadow: -3px 2px 8px #888888;
}

.apartment_name{
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: #fff;
    font-weight: 700;
    position: absolute;
    bottom: 10px;
    font-size: 1.3rem;
}

.btn_readmore_area{
    position: relative;
    margin-top: 30px;
    height: 20px;
}

.btn_readmore_apartment{
    color: #048c90;
    background-color: transparent;
    border: none;
    right: 0px;
    padding: 12px 0;
    width: fit-content;
    position: absolute;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1030px){
    .apartment_name{
        font-size: 1rem;
    }

    .project_hot_title{
        font-size: 1rem;
    }

    .project_hot, .apartment_by_project, .block_image_map{
        padding: 0 2rem;
    }

    .btn_readmore_area{
        margin-top: 30px;
    }

    .img_map{
        height: 350px;
    }

    .project_hot_title button{
        font-size: 0.9rem;
        padding: 12px 10px;
    }
}

@media only screen and (max-width: 930px){
    .apartment_name{
        font-size: 0.8rem;
        left: 25px;
    }

    .project_hot_title{
        font-size: 0.9rem;
    }

    .project_hot, .apartment_by_project, .block_image_map{
        padding: 0 1rem;
    }

    .btn_readmore_area{
        margin-top: 20px;
    }

    .btn_readmore_project, .btn_readmore_apartment{
        font-size: 0.9rem;
    }

    .img_map{
        height: 320px;
    }
}

@media only screen and (max-width: 930px){

    .project_hot_title button{
        font-size: 0.8rem;
        padding: 12px 8px;
    }

}

@media only screen and (max-width: 767px){

    .apartment_name{
        left: 10px;
    }

    .btn_readmore_area{
        margin-top: 10px;
    }

    .btn_readmore_project, .btn_readmore_apartment{
        font-size: 0.8rem;
    }

    .img_map{
        height: 300px;
    }
}

@media only screen and (max-width: 630px){

    .apartment_name{
        font-size: 0.75rem;
    }
}

@media only screen and (max-width: 570px){
    .apartment_name{
        font-size: 1rem;
    }

    .project_hot_title{
        font-size: 0.9rem;
    }

    .project_hot, .apartment_by_project, .block_image_map{
        padding: 0 3rem;
    }

    .btn_readmore_area{
        margin-top: 5px;
    }

    .btn_readmore_project, .btn_readmore_apartment{
        font-size: 0.7rem;
    }

    .img_map{
        height: 270px;
    }

    .project_hot_title button{
        font-size: 0.75rem;
    }
}

@media only screen and (max-width: 450px){
    .apartment_name{
        font-size: 0.85rem;
        left: 20px;
    }

    .project_hot_title{
        font-size: 0.8rem;
    }

    .project_hot, .apartment_by_project, .block_image_map{
        padding: 0 2rem;
    }

    .img_map{
        height: 230px;
    }

    .project_hot_title button{
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 400px){
    .apartment_name{
        font-size: 0.75rem;
    }

    .project_hot_title{
        font-size: 0.7rem;
    }

    .img_map{
        height: 220px;
    }

    .project_hot_title button{
        font-size: 0.63rem;
    }
}