.title_contact_infor {
    font-size: 2rem;
    top: 30px;
    color: black;
}

.description_contact {
    font-size: 1.2rem;
    top: 40px;
    color: black;
    padding: 0 20rem;
    color: #01908f;
}

.intro_button_2 {
    border-radius: 5px;
    background-color: #01908f;
    font-size: 0.9rem;
    width: 280px;
    height: 140px;
    padding: 12px;
    color: #fff;
    border: 3px solid black;
}

/*----------------------------------------*/

@media only screen and (max-width: 1070px) {

    .description_contact {
        padding: 0 12rem;
    }
}

@media only screen and (max-width: 991px) {

    .intro_button_2 {
        width: 230px;
        height: 125px;
        padding: 10px;
        font-size: 0.8rem;
    }

    .title_contact_infor {
        font-size: 1.8rem;
    }

    .description_contact {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 890px) {

    .description_contact {
        padding: 0 12rem;
    }

}

@media only screen and (max-width: 768px) {

    .title_contact_infor {
        font-size: 1.5rem;
    }

    .description_contact {
        font-size: 0.9rem;
        padding: 0 6rem;
    }

    .intro_button_2 {
        width: 180px;
        height: 90px;
        padding: 6px;
        font-size: 0.5rem;
    }
}

@media only screen and (max-width: 630px) {

    .contact_area {
        padding: 0 2rem;
    }

    .description_contact {
        padding: 0 5rem;
    }

    .intro_button_2 {
        width: 160px;
        height: 80px;
    }

}

@media only screen and (max-width: 576px) {

    .title_contact_infor {
        font-size: 1.4rem;
    }

    .description_contact {
        font-size: 0.8rem;
    }

    .intro_button_2 {
        font-size: 0.8rem;
        width: 230px;
        height: 115px;
    }

}

@media only screen and (max-width: 500px) {

    .description_contact {
        padding: 0 2rem;
    }

    .intro_button_2 {
        font-size: 0.65rem;
        width: 200px;
        height: 100px;
    }
}