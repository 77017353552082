
.section_flat_list .item-title{
    font-weight: 600;
    text-align: justify;
    color: #364465;
}

.block_flat_item{
    padding: 0 10%;
}

.section_flat_list{
    padding-top: 20px;
}

.item_area{
    display: flex;
    justify-content: center;
}

.item-image{
    overflow: hidden;
    position: relative;
}
.item-image img{
    width: 100%;
    max-height: 200px;
    object-fit:cover;
    overflow: hidden;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.item{
    margin-bottom: 30px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 4px;
    outline: 1px solid #01908f;
    outline-offset: 3px;
}

.item-description{
    padding:15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.item-description-top{
    height: 55px;
}

.item-description-top span{
    font-size: 0.9rem;
}

.item-description-top h4{
    font-size: 1.2rem;
}

.item-description a{
    color: #202020;
    text-decoration: none;
}
.item-description div:nth-child(1){
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
}
.item .fas{
    color:#364465
}
.item-icon{
    color:black;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
}

#btn-detail{
    background-color: #00908E;
    color:white;
    border-radius: 4px;
    width: 100%;
}

.item-detail{
    display: flex;
    justify-content: space-around;
    margin: 12px auto;
}

.detail{
    text-transform: capitalize;
    font-weight: 500;
    background-color: #D9D9D9;
    height: auto;
    width: 45px;
    border-radius: 4px;
    font-size: 0.9rem;
}

.item-price{
    color: #ffffff;
    width: 100%;
    display: flex;
    
    justify-content: space-between;
    position: absolute;
    padding: 0 10px;
    bottom: 5px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.25rem;
    text-shadow: 2px 2px 4px #202020;
}

.item-date{
    background-color: red;
    color: #ffffff;
    position: absolute;
    top: 20px;
    left: -50px;
    transform: rotate(-45deg);
    text-align: center;
    padding: 0 45px;
}

.item-date span{
    font-size: 0.65rem;
}

@media only screen and (max-width: 991px) {
    .item{
        width: 60%;
    }

    .detail{
        width: 55px;
    }
}

@media only screen and (max-width: 767px) {
    .item{
        width: 80%;
    }
}

@media only screen and (max-width: 430px) {
    .item{
        width: 95%;
    }

    .item-description-top{
        height: 50px;
    }

    .item-description-top h4{
        font-size: 1.1rem;
    }

    .item-description-top span{
        font-size: 0.8rem;
    }

    .detail{
        font-size: 0.8rem;
    }
}